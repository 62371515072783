<template>
  <TabbedPageTemplate testId="dashboard-credits-page">
    <NdsCardSpacing :gap="3" direction="column" center>
      <NdsCard>
        <template #default>
          <NdsText v-if="model.description.value" isRichText tight :text="model.description.value" />
          <div v-if="pending" class="align-items-center justify-content-center d-flex flex-fill">
            <NdsLoader />
          </div>
          <div v-else-if="failed" class="col-12">
            <Reloader @reload="getCreditInfo" />
          </div>
          <div v-else class="row flex-column flex-lg-row">
            <div class="col-12 col-lg-5">
              <NdsHeading title="UC Riskprognos" type="h4" tight>
                <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
                  @click="riskForecastQuestionModal.openModal" aria-label="UC Riskprognos förklaring" />
              </NdsHeading>
              <NdsRiskDoughnutChart title="" subTitle="Ditt betyg är" :value="riskGrade" />
              <div v-if="riskGrade === 0" class="col-12">
                <p v-html="model.descriptionNoScoreGrade.value" />
              </div>
              <NdsFooter :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: UC" tight />
            </div>
            <div class="col-12 col-lg-7 mt-4 mt-md-0">
              <NdsHeading title="Senaste frågor hos UC" type="h4" tight>
                <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
                  @click="latestCreditInquiriesQuestionModal.openModal" aria-label="Senaste frågor hos UC förklaring" />
              </NdsHeading>
              <ConsumerInquiries v-if="ctiConsumerInquires" :consumer-inquiries="ctiConsumerInquires" />
              <ZeroState v-else icon="far fa-check" text="Ingen förfrågan registrerad"
                data-testid="min-upplysning-general-zerostate" />
            </div>
          </div>
        </template>
      </NdsCard>
      <NdsCard v-if="pending">
        <template #default>
          <div class="align-items-center justify-content-center d-flex flex-fill">
            <NdsLoader />
          </div>
        </template>
      </NdsCard>
      <NdsCard v-else-if="!failed" tight>
        <template #default>
          <NdsTabs v-model="tabModel" :tabs="tabs" @back="onBack" @forward="onForward">
            <template #credits_tab1>
              <CreditCard :report="customerCreditInfo" :model="model" />
            </template>
            <template #credits_tab2>
              <DownPaymentCredit :report="customerCreditInfo" :model="model" />
            </template>
          </NdsTabs>
        </template>
      </NdsCard>
    </NdsCardSpacing>
  </TabbedPageTemplate>
  <NdsConfirmModal v-bind="riskForecastQuestionModal.props" v-model="riskForecastQuestionModal.isOpen.value"
    @onConfirm="riskForecastQuestionModal.closeModal" @onCancel="riskForecastQuestionModal.closeModal"
    @onDismiss="riskForecastQuestionModal.dismissModal" />
  <NdsConfirmModal v-bind="latestCreditInquiriesQuestionModal.props"
    v-model="latestCreditInquiriesQuestionModal.isOpen.value" @onConfirm="latestCreditInquiriesQuestionModal.closeModal"
    @onCancel="latestCreditInquiriesQuestionModal.closeModal"
    @onDismiss="latestCreditInquiriesQuestionModal.dismissModal" />
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, ref, onMounted } from 'vue';
import { NdsIconButton, NdsRiskDoughnutChart, NdsConfirmModal, NdsLoader, NdsCard, NdsTabs, NdsHeading, NdsCardSpacing, NdsFooter, NdsText, useConfirmModal, useTabs } from '@nds/vue';
import ConsumerInquiries from '../components/ConsumerInquiries.vue';
import CreditCard from '../components/CreditCard.vue';
import DownPaymentCredit from '../components/DownPaymentCredit.vue';
import ZeroState from "../components/myinformation/ZeroState.vue";
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import Reloader from '../components/Reloader.vue';
import TabbedPageTemplate from '../components/templates/TabbedPageTemplate.vue';
import { useEbbot } from '../utils/ebbotUtils.js';

const props = defineProps({
  model: Object
});

const store = useStore();
const pending = computed(() => store.state.customerCreditInfoContex.pending);
const failed = computed(() => store.state.customerCreditInfoContex.failed);
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const riskGrade = computed(() => store.state.customerCreditInfoContex.customerCreditInfo?.creditReport?.creditScore?.riskGrade ? store.state.customerCreditInfoContex.customerCreditInfo.creditReport.creditScore.riskGrade * 10 : 0);
const ctiConsumerInquires = computed(() => store.state.customerCreditInfoContex.customerCreditInfo?.creditReport?.ctiConsumerInquires?.inquiries?.length > 0 ? store.state.customerCreditInfoContex.customerCreditInfo?.creditReport.ctiConsumerInquires : null);

const { showEbbot, hideEbbot } = useEbbot();

const riskForecastQuestionModal = useConfirmModal({
  title: 'UC Riskprognos',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: props.model.questionRiskForecast.value,
    isRichText: true,
  },
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const latestCreditInquiriesQuestionModal = useConfirmModal({
  title: 'Senaste frågor hos UC',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: props.model.questionLatestCreditInquiries.value,
    isRichText: true,
  },
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const tabs = ref([{ id: 'credits_tab1', name: 'Kontokredit' }, { id: 'credits_tab2', name: 'Avbetalningskredit' }]);

const { tabModel, onBack, onForward } = useTabs({
  tabs,
  defaultTabId: "credits_tab1",
});


onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
});

const getCreditInfo = () => {
  store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
}
</script>