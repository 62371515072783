<template>
  <div class="datamonitor-block js-datamonitor">
    <div v-if="customerEssentialsPending" class="align-items-center justify-content-center d-flex">
      <NdsLoader />
    </div>
    <Reloader v-else-if="customerEssentialsFailed" @reload="reFetchCustomerEssentials" />
    <div v-else-if="customerEssentialsLoaded" class="row">
      <div class="col-12">
        <h3 v-if="model.headline.value" class="text">
          {{ model.headline.value }}
        </h3>
        <div v-if="model.mainBody.value" class="content">
          <p v-html="model.mainBody.value" />
        </div>
        <NdsButton variant="primary" width="full" :prefix="{ prefix: 'far', iconName: 'external-link' }"
          :class="customerEssentials.userHasAccessToDataMonitor && 'js-datamonitor-link'"
          @click="handleAccessToDataMonitorClick">
          {{ model.externalServiceUrlDescription.value }}
        </NdsButton>
      </div>
    </div>
  </div>
  <NdsConfirmModal v-bind="enrollModal.props" v-model="enrollModal.isOpen.value" @onConfirm="onConfirmEnrollSubmit"
    @onCancel="enrollModal.closeModal" @onDismiss="enrollModal.dismissModal">
    <template #body>
      <div>
        <div v-html="model.modalMainBody.value" />
        <form role="form">
          <div class="form-group">
            <label class="black-label" for="email">E-postadress</label>
            <input id="email" v-model="form.customerEmail" type="text" class="form-control"
              :class="v$.customerEmail.$error ? 'is-invalid' : ''" placeholder="E-postadress" @keyup="onDataChange">
            <small v-if="v$.customerEmail.email.$invalid" class="is-invalid">
              {{ v$.customerEmail.email.$message }}
            </small>
            <small v-else-if="v$.customerEmail.required.$invalid" class="is-invalid">
              {{ v$.customerEmail.required.$message }}
            </small>
            <small v-else-if="v$.customerEmail.isUnique.$invalid" class="is-invalid">
              E-postadress används redan, fyll i ett annat
            </small>
          </div>

          <div class="form-group">
            <div class="form-check-group">
              <input id="conditionsCheck" v-model="form.conditionsCheck" type="checkbox" class="check-input"
                @click="onDataChange">
              <label class="check-label" for="conditionsCheck">
                <span>Godkänn</span>
                <a :href="model.modalConditionsLink.value" class="ml-1" target="_blank">villkor</a>
              </label>
            </div>

            <small v-if="v$.conditionsCheck.sameAsRawValue.$invalid && dataChanged" class="check-is-invalid">
              Vänligen markera detta fält
            </small>
          </div>
        </form>
      </div>
    </template>
  </NdsConfirmModal>
  <NdsConfirmModal v-bind="errorModal.props" v-model="errorModal.isOpen.value" @onConfirm="errorModal.closeModal"
    @onCancel="errorModal.closeModal" @onDismiss="errorModal.dismissModal" />
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, reactive, ref, watch } from 'vue';
import { NdsButton, NdsLoader, NdsConfirmModal, useConfirmModal } from '@nds/vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers, sameAs } from '@vuelidate/validators';
import Reloader from '../components/Reloader.vue';
import { defaultGenericError } from '../components/modals/modalsUtils';
import { useEbbot } from '../utils/ebbotUtils.js';
import {
  CUSTOMER_INFO_FETCH,
  CUSTOMER_INFO_ENROLL_ONLINE_MONITORING,
} from '../Scripts/store/modules/customerInfoContex';

const props = defineProps({
  model: Object,
});

const store = useStore();
const dataChanged = ref(false);
const conflictErrorNotChanged = ref(false);
const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const redirectUrlFailed = computed(() => store.state.customerInfoContex.redirectUrlFailed);
const redirectUrlPending = computed(() => store.state.customerInfoContex.redirectUrlPending);
const onlineMonitoringEmailConflict = computed(() => store.state.customerInfoContex.onlineMonitoringEmailConflict);
const customerEssentialsLoaded = computed(() => store.state.customerInfoContex.loaded);
const customerEssentialsFailed = computed(() => store.state.customerInfoContex.failed);
const customerEssentialsPending = computed(() => store.state.customerInfoContex.pending);

import {
  CUSTOMER_INFO_OPEN_ONLINE_MONITORING,
} from '../Scripts/store/modules/customerInfoContex';


const form = reactive({
  customerEmail: customerEssentials.value.customerEmail,
  conditionsCheck: false,
});

const isUniqueEmailHelper = (value) => {
  if (value === '') {
    return true
  }
  return !onlineMonitoringEmailConflict.value || conflictErrorNotChanged.value
}


const rules = computed(() => {
  return {
    customerEmail: {
      required: helpers.withMessage('Vänligen fyll i detta fält', required),
      email: helpers.withMessage('Vänligen fyll i en giltig e-postadress', email),
      isUnique: isUniqueEmailHelper,
    },
    conditionsCheck: {
      sameAsRawValue: sameAs(true),
    },
  }
});

const v$ = useVuelidate(rules, form);

const onDataChange = () => {
  conflictErrorNotChanged.value = true;
  if (!v$.value.$error) {
    dataChanged.value = true;
  } else {
    dataChanged.value = false;
  }
};

const onConfirmEnrollSubmit = () => {
  if (v$.value.$invalid) {
    return dataChanged.value = true;
  }
  store.dispatch(CUSTOMER_INFO_ENROLL_ONLINE_MONITORING, {
    monitoringBlockId: props.model.contentLink.id,
    email: form.customerEmail,
  });
}

const { showEbbot, hideEbbot } = useEbbot();

const enrollModal = useConfirmModal({
  title: props.model.modalHeadline.value,
  variant: 'primary',
  prefix: { prefix: "far", iconName: "globe" },
  confirmBtnLabel: props.model.modalSubmitButtonText.value || "Stäng",
  testId: 'enroll-onlinemonitoring-modal',
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const errorModal = useConfirmModal({
  ...defaultGenericError,
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

watch(redirectUrlFailed, () => {
  if (redirectUrlFailed.value) {
    errorModal.openModal()
  }
});

watch(redirectUrlPending, (value) => {
  if (value) {
    conflictErrorNotChanged.value = false;
    return enrollModal.setState({ status: "pending", message: "Veriferar din e-postadress" });
  } else if (enrollModal.isOpen.value && onlineMonitoringEmailConflict.value) {
    return enrollModal.setState({ status: "default" });
  }
  return enrollModal.closeModal();
});

const handleAccessToDataMonitorClick = () => {
  if (customerEssentialsLoaded.value && customerEssentials.value.userHasAccessToDataMonitor) {
    return store.dispatch(CUSTOMER_INFO_OPEN_ONLINE_MONITORING, {
      monitoringBlockId: props.model.contentLink.id,
    });
  }
  conflictErrorNotChanged.value = false;
  enrollModal.setState({ status: "default" });
  return enrollModal.openModal();
}

const reFetchCustomerEssentials = () => {
  store.dispatch(CUSTOMER_INFO_FETCH, { reFetch: true });
}
</script>