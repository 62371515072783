<template>
  <div id="digitalmailboxpage" class="dashboard" data-testid="dashboard-digital-mailbox-page">
    <div class="row">
      <div class="col-12 mb-2 d-md-none mobile-return-overview-btn">
        <RouterLink to="/mina-tjanster" v-slot="{ href, navigate, isActive }" custom>
          <NdsLink :href="href" :active="isActive" @click="navigate" :prefix="{ prefix: 'far', iconName: 'home-alt' }">
            Översikt
          </NdsLink>
        </RouterLink>
      </div>
      <div class="col-12">
        <NdsCardSpacing :gap="3" direction="column" center>
          <NdsCard :heading="{ title: model.name, type: 'h3', prefix: { prefix: 'far', iconName: 'envelope' } }">
            <template #default>
              <Reloader v-if="failed" @reload="fetchDigitalMailBoxMessages" />
              <template v-else>
                <div class="d-flex align-items-center mb-4">
                  <span class="font-weight-medium">Status</span>
                  <input id="switch" v-model="isActive" type="checkbox" class="enento__toggle-input" :disabled="pending"
                    @change="toggleDigitalMailbox">
                  <label class="enento__toggle mb-0 ml-auto ml-md-0" :class="pending && 'enento__toggle-disabled'"
                    for="switch" data-testid="digital-brevlada-page-switch-btn">
                    <i class="far fa-check fade-in-half" />
                    <span class="enento__toggle-on">På</span>
                    <span class="enento__toggle-off">Av</span>
                  </label>
                </div>
                <div v-if="pending" class="align-items-center justify-content-center d-flex">
                  <NdsLoader />
                </div>
                <template v-else-if="isActive">
                  <p class="mb-4" v-html="model.descriptionWhenActivated.value" />
                  <DigitalMailBoxMessages :messages="messages" :paging-enabled="true" :items-per-page="10" />
                </template>
                <p v-else class="mb-4" v-html="model.descriptionWhenDisabled.value" />
              </template>
            </template>
          </NdsCard>
          <NdsCard v-if="isActive"
            :heading="{ title: 'Om Digital Brevlåda', type: 'h3', prefix: { prefix: 'far', iconName: 'question-circle' } }"
            testId="digital-brevlada-page-about-digital-brevlada-container">
            <template #default>
              <span v-html="model.aboutText.value" />
            </template>
          </NdsCard>
        </NdsCardSpacing>
      </div>
    </div>
  </div>
  <NdsConfirmModal v-bind="errorModal.props" v-model="errorModal.isOpen.value" @onConfirm="errorModal.closeModal"
    @onCancel="errorModal.closeModal" @onDismiss="errorModal.dismissModal" />
</template>
<script setup>
import { ref, onMounted } from 'vue';
import DigitalMailBoxMessages from '../components/DigitalMailBoxMessages.vue';
import { NdsLoader, NdsLink, NdsCard, NdsCardSpacing, NdsConfirmModal, useConfirmModal } from '@nds/vue';
import Reloader from '../components/Reloader.vue';
import { defaultGenericError } from '../components/modals/modalsUtils';
import { useEbbot } from '../utils/ebbotUtils.js';
import { getGetDigitalMailBoxMessages, postSetDigitalMailBoxActive } from '../Scripts/providers/frontendApiControllerProviders';

const props = defineProps({
  model: Object,
});

const messages = ref([]);
const isActive = ref(false);
const failed = ref(false);
const pending = ref(false);

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  fetchDigitalMailBoxMessages();
});

const { showEbbot, hideEbbot } = useEbbot();

const errorModal = useConfirmModal({
  ...defaultGenericError,
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const fetchDigitalMailBoxMessages = () => {
  getGetDigitalMailBoxMessages().then(response => {
    messages.value = response.data?.MessageList ? response.data.MessageList : [];
    isActive.value = Boolean(response.data?.Activated);
    failed.value = false;
    pending.value = false;
  }).catch(() => {
    messages.value = [];
    isActive.value = false;
    failed.value = true;
    pending.value = false;
  })
}

const toggleDigitalMailbox = () => {
  failed.value = false;
  pending.value = true;
  postSetDigitalMailBoxActive({ setActive: !isActive.value ? "OFF" : "ON" }).then(() => {
    fetchDigitalMailBoxMessages();
  }).catch(() => {
    errorModal.openModal();
  })
}
</script>
