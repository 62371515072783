<template>
  <div data-testid="minupplysning-credits-tab">
    <div class="py-4">
      <h3 :class="headerClass">
        <i class="far fa-sack-dollar fa-18 mr-2" />Kreditengagemang
      </h3>
      <div v-if="shouldShowIntroText" v-html="introText" />
    </div>

    <div>
      <div v-if="shouldShowCreditSummary" class="row px-2 mb-4">
        <div class="col-3 px-3 py-2">Totalt utnyttjat kredit</div>
        <div class="col-3 px-3 py-2 color-ocean font-weight-medium"
          data-testid="min-upplysning-credits-total-used-amount">
          {{ totalUsedCredits }}
        </div>
        <div class="col-3 px-3 py-2">Antal krediter</div>
        <div class="col-3 px-3 py-2 font-weight-medium" data-testid="min-upplysning-credits-nr-of-credits">
          {{ totalNumberOfCredits }}
        </div>
        <div class="col-3 px-3 py-2">Totalt beviljad kredit</div>
        <div class="col-3 px-3 py-2 color-ocean font-weight-medium"
          data-testid="min-upplysning-credits-total-granted-amount">
          {{ totalGrantedCredits }}
        </div>
        <div class="col-3 px-3 py-2">Antal kreditgivare</div>
        <div class="col-3 px-3 py-2 font-weight-medium" data-testid="min-upplysning-credits-nr-of-creditors">
          {{ totalNumberOfCreditors }}
        </div>
      </div>

      <div v-if="shouldShowCreditSummary" class="dashboard__card py-2 pb-3 mb-3 d-none d-md-block">
        <div class="row">
          <ul class="nav nav-tabs flex-nowrap border-0 w-100">
            <li v-for="tab in tabs" :key="tab.id" class="d-flex nav-item text-nowrap">
              <a :data-toggle="'tab'" :href="tab.href" :data-testid="tab.testId" :class="getActiveClass(tab.id)">
                {{ tab.label }}
              </a>
            </li>
            <li class="d-flex flex-fill nav-item">
              <a href="#" class="pe-none flex-fill" />
            </li>
          </ul>
        </div>

        <div class="tab-content py-3">
          <div v-for="tab in tabs" :key="tab.id" :id="tab.id" class="tab-pane pt-0" :class="getActiveClass(tab.id)">
            <CreditsTab v-if="tab.creditType" :credit-type="tab.creditType" :report="report" :title="tab.label" />
            <Csn v-else-if="!tab.creditType && report.creditReport.ctiCsnLoan"
              :cti-csn-loan="report.creditReport.ctiCsnLoan" />
            <ZeroState v-else icon="far fa-graduation-cap" text="Inget CSN-lån registrerat"
              data-testid="min-upplysning-credits-csn-zerostate" />
            <span v-if="!tab.creditType" class="body-xsmall mt-2 mb-0"><i class="far fa-book mr-1"
                data-testid="min-upplysning-credits-csn-source" />Källa: CSN
            </span>
          </div>
        </div>
      </div>

      <ZeroState v-else icon="far fa-sack-dollar" text="Ingen kredit registrerad" />

      <span class="d-block body-xsmall px-2 pb-3">
        <i class="far fa-book mr-1" />Källa: Kreditgivare i Sverige
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { formatToSek, formatToAmount } from '../../_helpers.js';
import ZeroState from '../../components/myinformation/ZeroState.vue';
import CreditsTab from '../../components/myinformation/CreditsTab.vue';
import Csn from '../../components/Csn.vue';

const props = defineProps({
  report: Object,
  introText: String,
});

const activeTab = ref('blanco-tab');

const shouldShowIntroText = computed(() => props.report.creditReport.ctiCreditSummary && props.introText || props.report.creditReport.ctiCsnLoan);

const shouldShowCreditSummary = computed(() => props.report.creditReport.ctiCreditSummary || props.report.creditReport.ctiCsnLoan);

const headerClass = computed(() => shouldShowIntroText.value ? 'mb-4' : 'mb-0');

const getActiveClass = (tabId) => activeTab.value === tabId ? 'active' : '';

const getCreditSummary = (creditKey) => props.report.creditReport.ctiCreditSummary && props.report.creditReport.dailyCreditSummary?.creditSummaries?.[0]?.[creditKey] ? props.report.creditReport.dailyCreditSummary.creditSummaries[0][creditKey] : 0;

const totalUsedCredits = computed(() => formatToSek(getCreditSummary('usedCredits')));
const totalNumberOfCredits = computed(() => formatToAmount(getCreditSummary('numberOfCredits')));
const totalGrantedCredits = computed(() => formatToSek(getCreditSummary('grantedCredits')));
const totalNumberOfCreditors = computed(() => formatToAmount(getCreditSummary('numberOfCreditors')));

const tabs = ref([
  { id: 'blanco-tab', label: 'Blanco', href: '#blanco-tab', testId: 'min-upplysning-credits-blanco-tab', creditType: 5 },
  { id: 'downpayment-tab', label: 'Avbetalningar', href: '#downpayment-tab', testId: 'min-upplysning-credits-downpayment-tab', creditType: 4 },
  { id: 'creditcard-tab', label: 'Kontokredit', href: '#creditcard-tab', testId: 'min-upplysning-credits-credicard-tab', creditType: 1 },
  { id: 'property-tab', label: 'Fastighet', href: '#property-tab', testId: 'min-upplysning-credits-properties-tab', creditType: 7 },
  { id: 'tenantownership-tab', label: 'Bostadsrätt', href: '#tenantownership-tab', testId: 'min-upplysning-credits-tenant-tab', creditType: 9 },
  { id: 'csn-tab', label: 'CSN-lån', href: '#csn-tab', testId: 'min-upplysning-credits-csn-tab' },
]);

onMounted(() => {
  if (props.report.creditReport.ctiCreditsWithInquirer) {
    for (const item of props.report.creditReport.ctiCreditsWithInquirer.creditInformations) {
      const creditType = props.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber];
      if (creditType === 5) {
        activeTab.value = 'blanco-tab';
        return;
      } else if (creditType === 4) {
        activeTab.value = 'downpayment-tab';
        return;
      } else if (creditType === 1) {
        activeTab.value = 'creditcard-tab';
        return;
      } else if (creditType === 7) {
        activeTab.value = 'property-tab';
        return;
      } else if (creditType === 9) {
        activeTab.value = 'tenantownership-tab';
        return;
      }
    }
  } else if (props.report.creditReport.ctiCsnLoan !== null) {
    activeTab.value = 'csn-tab';
    return;
  }
});

</script>