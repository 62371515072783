export function NumberFormat(sum) {
    return Number(sum).toLocaleString('sv');
}

export function NumberFormatSek(sum) {
    return Number(sum).toLocaleString('sv', {
        style: "currency",
        currency: "SEK",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
}

export function FormatDate(timestamp) {
    return new Date(timestamp).toLocaleDateString('sv-SE');
}

export function FormatDateToSwedish(timestamp) {
    const options = {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        timeZone: 'Europe/Stockholm',
        locale: 'sv-SE'
    };
    return new Date(timestamp).toLocaleDateString('sv-SE', options);
}

export function FormatDateRemoveDays(timestamp) {
    return new Date(timestamp).toLocaleDateString('sv-SE').slice(0, -3);
}

export function OddOrEven(index) {
    return (index % 2) > 0 ? "odd" : "even";
}

export function FormatPersonId(number) {
    const str = number.toString();
    return str.slice(0, 8) + "-" + str.slice(8);
}
export function FormatPersonIdSmall(number) {
    const str = number.toString();
    return str.slice(0, 6) + "-" + str.slice(6);
}

export function FormatPostalCode(number) {
    const str = String(number);
    const formattedNumber = str.slice(0, 3) + ' ' + str.slice(3);
    return formattedNumber;
}

export function NumberAddDashTo4th(date) {
    const result = date.slice(0, 4) + '-' + date.slice(4);
    return result;
}

export const formatToSek = (credit) => {
    if (credit === 0) {
        return "0 kr";
    }
    return NumberFormatSek(credit);
}

export const formatToAmount = (count) => {
    if (count === 0) {
        return "0 st";
    }
    return NumberFormat(count) + " st";
}

export const formatStatusCode = (statusCode) => {
    switch (statusCode) {
        case 'NOT_REGISTERED':
            return "Inget registrerat hos UC";
        case 'DIVORCED':
            return "Skild";
        case 'MARRIED':
            return "Gift";
        case 'UNMARRIED':
            return "Ogift";
        case 'REGISTERED':
            return "Registrerad";
        default:
            return statusCode;
    }
}